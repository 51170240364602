import React from 'react';
import { SparklesCore } from './ui/sparkles';

const PreviewGenerator = () => {
  return (
    <div 
      style={{
        width: '1200px',
        height: '630px',
        background: '#000000',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#ffffff',
        fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif'
      }}
    >
      <div style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 1 }}>
        <SparklesCore
          id="preview-sparkles"
          background="transparent"
          minSize={0.4}
          maxSize={2}
          particleDensity={200}
          className="w-full h-full"
          particleColor="#ffffff"
          speed={0.2}
        />
      </div>
      <div style={{ 
        zIndex: 2, 
        textAlign: 'center',
        padding: '3rem',
        background: 'rgba(0,0,0,0.3)',
        borderRadius: '2rem',
        backdropFilter: 'blur(20px)',
        border: '1px solid rgba(255,255,255,0.1)'
      }}>
        <p style={{ 
          fontSize: '3.5rem',
          lineHeight: 1.2,
          fontWeight: '300',
          letterSpacing: '0.05em',
          background: 'linear-gradient(to bottom, #ffffff, #a0a0a0)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          textShadow: '0 0 30px rgba(255,255,255,0.3)'
        }}>
          Creative Technologist
        </p>
      </div>
    </div>
  );
};

export default PreviewGenerator; 